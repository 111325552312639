import React, { useState } from "react"
import { LegacyCard, Collapsible, LegacyStack } from "@shopify/polaris"
import CircleColorPicker from "./CircleColorPicker"
import update from "immutability-helper"
import CardDivider from "../../../components/cardDivider"
export default function ColorsForm({
  ButtonWrapper,
  dropdownButtonMarkup,
  state,
  setState,
}) {
  const [open, setOpen] = useState(0)
  const handleToggle = (index) => {
    if (open === index) {
      setOpen(-1)
    } else {
      setOpen(index)
    }
  }
  const colorSettings = [
    /*
    {
      title: "Canvas",
      subtitle:
        "Applies to the body background for loading pages (checkout, payment processing)",
      id: "canvas",
    },
    {},
    */
    {
      title: "Color 1",
      subtitle: "Main checkout form",
      id: "color1",
    },
    {},
    {
      title: "Color 2",
      subtitle: "Order Summary",
      id: "color2",
    },
    {},
    {
      title: "Primary",
      subtitle: "Primary button",
      id: "primary",
    },
    {},
    {
      title: "Interactive",
      subtitle: "Links and interactive elements",
      id: "interactive",
    },
    {},
    {
      title: "Critical",
      subtitle: "Errors and other critical information",
      id: "critical",
    },
    {},
  ]

  return (
    <LegacyCard title="Colors">
      <CardDivider/>
      {colorSettings.map((colorSetting, index) => {
        // if index is odd, then it's a collapsible section
        return index % 2 ? (
          // if open is equal to index - 1, then it's open
          open === index - 1 ? (
            <LegacyCard.Section key={index}>
              <Collapsible
                open={open === index - 1}
                id={`${colorSettings[index - 1].title}-collapsible`}
                transition={{
                  duration: "500ms",
                  timingFunction: "ease-in-out",
                }}
                expandOnPrint
              >
                <LegacyCard sectioned subdued>
                  <LegacyStack distribution="fillEvenly">
                    <LegacyStack.Item>
                      <CircleColorPicker
                        title="Accent"
                        value={state[`${colorSettings[index - 1].id}Accent`]}
                        onChange={(value) => {
                          setState(
                            update(state, {
                              [`${colorSettings[index - 1].id}Accent`]: {
                                $set: value,
                              },
                            })
                          )
                        }}
                      />
                    </LegacyStack.Item>
                    <LegacyStack.Item>
                      <CircleColorPicker
                        title="Background"
                        value={
                          state[`${colorSettings[index - 1].id}Background`]
                        }
                        onChange={(value) => {
                          setState(
                            update(state, {
                              [`${colorSettings[index - 1].id}Background`]: {
                                $set: value,
                              },
                            })
                          )
                        }}
                      />
                    </LegacyStack.Item>
                    <LegacyStack.Item>
                      <CircleColorPicker
                        title="Foreground"
                        value={
                          state[`${colorSettings[index - 1].id}Foreground`]
                        }
                        onChange={(value) => {
                          setState(
                            update(state, {
                              [`${colorSettings[index - 1].id}Foreground`]: {
                                $set: value,
                              },
                            })
                          )
                        }}
                      />
                    </LegacyStack.Item>
                  </LegacyStack>
                </LegacyCard>
              </Collapsible>
            </LegacyCard.Section>
          ) : null
        ) : (
          // if index is even, then it's a button
          <LegacyCard.Section key={index}>
            <ButtonWrapper
              onClick={(e) => {
                e.preventDefault()
                handleToggle(index)
              }}
              ariaExpanded={open === index}
              ariaControls={`${colorSetting.title}-collapsible`}
            >
              {dropdownButtonMarkup(
                colorSetting.title,
                colorSetting.subtitle,
                open === index
              )}
            </ButtonWrapper>
          </LegacyCard.Section>
        );
      })}
    </LegacyCard>
  );
}
